const baseApiUrl = 'https://children-monitor-dev.sintrasviluppo.it/';
const apiUrl = `${baseApiUrl}api/`;
const publicApiUrl = baseApiUrl;

export const environment = {
  production: false,
  name: 'remote-development',
  appName: 'Children Monitor',
  apiUrl,
  publicApiUrl,
  audience: apiUrl.slice(0, -1),
  authInterceptorUrls: [apiUrl, publicApiUrl],
  httpRetryConfig: {
    enabled: true,
    verbs: {
      get: { enabled: true, timeout: 6000, maxRetries: 2, retryForStatusesCodes: [500] },
      post: { enabled: true, timeout: 6000, maxRetries: 0, retryForStatusesCodes: [500] },
      patch: { enabled: true, timeout: 6000, maxRetries: 0, retryForStatusesCodes: [500] },
      put: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      delete: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
    },
  },
  loggerConfig: {
    enabled: true,
    maxLogRentention: 20,
    logHttpErrorsWithStatusesCodes: ['4XX', '500'],
    logDecoratedExceptions: true,
    logExceptions: true,
    logUrl: 'https://logs.logdna.com/logs/ingest?apikey=bf8cf553dba4a1e882fa09491aaed2c7',
    sendLogs: true,
    sendRequestBody: true,
    sendQueryParams: true,
  },
  registerServiceWorker: true,
  sockets: {
    url: '',
  },
  mockApiUrl: 'https://localhost:3002/',
};
